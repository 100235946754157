import { graphql, PageProps } from "gatsby";
import { find } from "lodash";
import React, { useContext, useEffect } from "react";
import Layout from "../../components/layout";
import Navbar from "../../components/navbar";
import Seo from "../../components/seo";
import {
  TideItemForHotelConnection,
  TideItemForSporten,
  TideItemForTeam,
  TideItemForToernooiConnection,
  TideItemForWebsiteConnection,
  TideItemForWedstrijdConnection,
  TideTag,
} from "../../types";
import {
  findGameById,
  findSeasonGameById,
  findTournamentById,
  getChildItemsWithTypename,
  getParentItemWithTypename,
  parse,
} from "../../utils/component";
import GlobalContext from "../../contexts/global-provider";
import Booking from "../../components/booking";

import { BookingProvider } from "../../contexts/booking-provider";
import Flyin from "../../components/flyin/flyin";
import { ComponentContext, ComponentContextType } from "../../contexts/component-provider";

export interface BookingPageData {
  websites: TideItemForWebsiteConnection;
  events: TideItemForWedstrijdConnection;
  tournaments: TideItemForToernooiConnection;
  cmsHotels: TideItemForHotelConnection;
  tradeTrackerTag: TideTag;
}
interface BookingContext {
  websiteName: string;
}

const BookingPageTemplate: React.FC<PageProps<BookingPageData, BookingContext>> = ({ data, pageContext: { websiteName } }) => {
  const { language, setAffiliate } = useContext(GlobalContext);
  const { games, setGlobalData } = useContext<ComponentContextType>(ComponentContext);

  const globalContext = useContext(GlobalContext);

  useEffect(() => {
    if (globalContext) {
      if (language == "") {
        globalContext.setLanguage("nl-BE");
      }
      globalContext.setIsBookingPage(true);
    }
  }, [globalContext]);

  var queryParameters = new URLSearchParams();
  if (typeof window !== "undefined") {
    queryParameters = new URLSearchParams(window.location.search);
  }
  const bookingNr = queryParameters?.get("bookingNr");
  const bookingAllotmentName = queryParameters?.get("Name");
  const bookingAmount = queryParameters?.get("amount");
  const eventId = queryParameters?.get("EventId");

  useEffect(() => {
    (async () => {
      const affiliate = queryParameters?.get("vrp");
      if (affiliate && affiliate !== "") {
        setAffiliate(affiliate);
      }

      if (!games || games.length == 0) {
        await setGlobalData();
      }
    })();
  }, []);

  const event = findGameById(data.events.nodes, parse(eventId));
  const seasonEvent = findSeasonGameById(event, parse(eventId));
  const tournament = findTournamentById(data.tournaments.nodes, seasonEvent?.content?.general?.tournamentId);
  const sport = getParentItemWithTypename("TideItemForSporten", tournament) as TideItemForSporten;

  const website = find(data.websites.nodes, { name: websiteName, language: language ? language : "nl-BE" });

  const teams = getChildItemsWithTypename("TideItemForTeam", sport) as TideItemForTeam[];
  const homeTeam = teams?.find((t) => t?.content?.general?.id == event?.content?.general?.homeTeamId);
  const awayTeam = teams?.find((t) => t?.content?.general?.id == event?.content?.general?.awayTeamId);

  return (
    <Layout>
      <Seo title={`Booking ${websiteName}`} description={`Booking ${websiteName}`} keywords={`Booking ${websiteName}`} website={parse(website)} />
      <Navbar website={website} extraClassName="navbar--booking" isBooking={true} />

      <BookingProvider queryParameters={queryParameters} tradeTrackerTag={data.tradeTrackerTag}>
        <Booking
          bookingNr={parse(bookingNr)}
          bookingAllotmentName={parse(bookingAllotmentName)}
          bookingAmount={parse(bookingAmount ? parseFloat(bookingAmount) : null)}
          website={website}
          event={event}
          eventId={parse(eventId)}
          tournament={tournament}
          venueId={parse(seasonEvent?.content?.general?.venueId)}
          backgroundImage={parse(sport?.content?.general?.bookingbackgroundimage)}
          homeTeamImage={parse(homeTeam?.content?.general?.logo)}
          awayTeamImage={parse(awayTeam?.content?.general?.logo)}
          cmsHotels={data.cmsHotels.nodes}
          adultsOnly={parse(sport?.content?.general?.adultsOnly)}
        />
        <Flyin />
      </BookingProvider>
    </Layout>
  );
};

export default BookingPageTemplate;

export const query = graphql`
  query BookingPageQuery {
    websites: allTideItemForWebsite {
      nodes {
        name
        id
        language
        content {
          bookingFlow {
            initialsInfo
          }
          general {
            phone
            helptext
            linkSgr
            linkThuiswinkelWaarborg
            linkTelephone
            logo {
              altText
              url
              title
            }
            linkLogo
          }
        }
      }
    }
    events: allTideItemForWedstrijd {
      nodes {
        name
        language
        content {
          general {
            homeTeamId
            awayTeamId
            thumbnail {
              title
              altText
              url
            }
            introImages {
              title
              altText
              url
            }
            title
          }
        }
        childItems {
          ... on TideItemForSeizoenWedstrijd {
            __typename
            content {
              general {
                id
                venueId
                tournamentId
              }
            }
          }
        }
      }
    }
    tournaments: allTideItemForToernooi {
      nodes {
        id
        name
        content {
          general {
            title
            logo {
              title
              altText
              url
            }
          }
        }
        childItems {
          ... on TideItemForSeizoenToernooi {
            __typename
            content {
              general {
                id
              }
            }
          }
        }
        parentItem {
          ... on TideItemForSporten {
            __typename
            id
            name
            content {
              general {
                path
                bookingbackgroundimage {
                  title
                  altText
                  url
                }
                adultsOnly
              }
            }
            childItems {
              ... on TideItemForTeam {
                __typename
                name
                content {
                  general {
                    id
                    logo {
                      title
                      altText
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    cmsHotels: allTideItemForHotel {
      nodes {
        content {
          general {
            title
            tripadvisor
            stars
            entity {
              code
              accommodations {
                code
                localizedNames {
                  value
                  languageCode
                }
              }
              regimes {
                code
                localizedNames {
                  value
                  languageCode
                }
              }
            }
          }
          description {
            text
            images {
              title
              altText
              url
            }
            facilities {
              ... on TideItemForFacility {
                id
                name
                content {
                  general {
                    title
                    iconFontAwesome
                    hotelbedscode
                  }
                }
              }
            }
          }
        }
      }
    }
    tradeTrackerTag: tideTag(name: { eq: "TradeTracker" }) {
      tideId
    }
  }
`;
